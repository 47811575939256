@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-size: min(16px, 2.6vw);
  }

  body, html, #root {
    height: 100%;
    width: 100%;
    position: fixed;
    overflow: hidden;
    background-color: black;
  }

  @media not screen and (hover) {
    * {
      cursor: default !important;
    }
  }

  @keyframes cube-float {
    0% {
      filter: blur(0px);
      transform: scale(1) rotate(-1deg) translate3d(3px, 2px, 0px);
    }
    60% {
      filter: blur(0px);
      transform: scale(0.98) rotate(-2deg);
    }
    90% {
      filter: blur(1px);
      transform: scale(1.01) rotate(-2deg) translate3d(3px, -2px, 0px);
    }
    100% {
      filter: blur(0px);
      transform: scale(1) rotate(-1deg) translate3d(3px, 2px, 0px);
    }
  }

  .animate-cube-float {
    transform-origin: center center;
    animation: cube-float 4s ease-in-out infinite;
  }

  .delay-100 {
    animation-delay: 100ms;
  }

  .delay-500 {
    animation-delay: 500ms;
  }

  @keyframes loader-logo {
    0% {
      transform: scale(0.7) rotate(0deg);
    }

    45% {
      transform: scale(0.8) rotate(0deg);
    }

    50% {
      transform: scale(0.7) rotate(180deg);
    }

    95% {
      transform: scale(0.8) rotate(180deg);
    }

    100% {
      transform: scale(0.7) rotate(0deg);
    }
  }

  .animate-loader-logo {
    animation: loader-logo 5s ease-in-out infinite;
  }

  .tooltip-vulnerability * {
    max-width: 16rem !important;
  }
  
  .toast-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: transparent !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    padding: 1rem !important;
    margin: 0 !important;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}